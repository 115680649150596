import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Grid, Cell } from '@trbl/react-css-grid';
import Image from 'gatsby-image';
import './MachineHeader.scss';

const MachineHeader = React.memo(({
  title,
  background,
  specs,
  color,
}) => {
  const { sideImage, sideImageMobile } = useStaticQuery(graphql`
    query machineHeaderImage {
      sideImage: file(relativePath: {eq: "psu-fans.jpg"}) {
        childImageSharp {
          fluid(maxHeight: 720, maxWidth: 300 quality: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sideImageMobile: file(relativePath: {eq: "psu-fans-mobile.jpg"}) {
        childImageSharp {
          fluid(quality: 50, maxWidth: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const sideImageSources = [
    sideImageMobile.childImageSharp.fluid,
    {
      ...sideImage.childImageSharp.fluid,
      media: `(min-width: 640px)`,
    },
  ];
  return (
    <div className="wmfab__machine-header">
      <Grid
        className="wmfab__machine-header--grid"
      >
        <Cell
          className="wmfab__machine-header--container"
          hStart={6}
          hSpan={11}
          hStartM={3}
          hSpanM={14}
          hStartS={1}
          hSpanS={16}
        >
          <Image
            className="wmfab__machine-header--background"
            fluid={background.childImageSharp.fluid}
            style={{ height: "100%"}}
            fadeIn={false}
            loading="eager"
          />
          <div className="wmfab__machine-header--background-overlay-gradient" />
          <div
            className={`
              wmfab__machine-header--background-overlay
              wmfab__overlay--${color ? color : ''}
            `}
          />
        </Cell>
      </Grid>
      <Grid
        className="wmfab__machine-header--side"
      >
        <Cell
          className="wmfab__machine-header--side-container"
          hStart={0}
          hSpan={6}
          hSpanL={6}
          hSpanM={7}
          hSpanS={15}
        >
          <Image
            fluid={sideImageSources}
            style={{
              position: 'absolute',
              objectFit: 'cover'
            }}
            loading="eager"
            fadeIn={false}
            className="wmfab__machine-header--side-background"
          />
          <div className="wmfab__machine-header--side-overlay" />
          <Grid className="wmfab__machine-header--content-container">
            <Cell
              hStart={0}
              hSpan={5}
              hSpanL={5}
              hSpanM={7}
              hSpanS={14}
              className="wmfab__machine-header--content"
            >
              <header>
                <h1>
                  <span className="wmfab__type--subheader">
                    Machines—
                  </span>
                  {title}
                </h1>
              </header>
              <section>
                <div className="wmfab__machine-header--specs-list">
                  <table>
                    <tbody>
                      {specs.map(({ name, value }) => (
                        <tr key={name}>
                          <td
                            className="wmfab__machine-header-spec-name"
                          >
                            {name}
                          </td>
                          <td
                            className="wmfab__machine-header-spec-value"
                          >
                            {value}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </section>
            </Cell>
          </Grid>
        </Cell>
      </Grid>
    </div>
  );
});

export default MachineHeader;
